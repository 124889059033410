<template>
  <div>
    <slot name="header" />
    <div
      class="mx-auto max-w-sm"
      v-if="data.field.type !== 'textarea'">
      <div class="my-8">
        <input-field
          v-model="data.model"
          :type="data.field.type"
          :label="data.field.title"
          class="mb-4" />
      </div>
    </div>
    <div
      class="mx-auto max-w-3xl"
      v-else>
      <div class="my-8">
        <input-textarea
          v-model="data.model"
          :label="data.field.title"
          class="mb-4" />
      </div>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import validate from "validate.js"

export default {
  props: {
    data: {
      type: Object,
      default () {
        return {}
      }
    }
  },

  computed: {
    modelErrors () {
      return validate.single(this.data.model, {
        presence: true,
        length: {
          minimum: 2,
          message: "must be at least 3 characters"
        }
      })
    },

    isValid () {
      return (typeof this.modelErrors === "undefined")
    }
  }
}
</script>
